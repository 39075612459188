import {
	// FAQModuleType,
	MetaModuleType,
} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import Head from "next/head"
import {useRouter} from "next/router"
import React from "react"

const HeadMeta = (props: {meta?: MetaModuleType}) => {
	const metaData = props.meta
	// const {metaData} = useContext(MetaDataContext)
	// Define current locale
	const router = useRouter()

	const URL = `https://dreambroker.com${router.asPath}`

	// const mainEntity = metaData?.faq?.map(
	// 	(element: FAQModuleType, index: number) => {
	// 		return {
	// 			"@type": "Question",
	// 			name: element.question,
	// 			acceptedAnswer: {
	// 				"@type": "Answer",
	// 				text: element.answer,
	// 			}
	// 		}
	// 	},
	// )

	// function addProductJsonLd() {
	// 	return {
	// 		__html: `{
	// 	  "@context": "https://schema.org/",
	// 	  "@type": "${metaData?.ogType}",
	// 	  "headline": "${metaData?.title}",
	// 	  "description": "${metaData?.ogDescription}",
	// 	  "image": "${metaData?.ogImage}",
	// 	  "url": "${URL}",
	// 	  "author": {
	// 		"@type": "Organization",
	// 		"name": "Dream Broker"
	// 	  }${mainEntity ? ',' : ''}
	// 	  ${mainEntity ? `"mainEntity": ${JSON.stringify(mainEntity, null, 2)}` : ""}
	// 	}
	//   `,
	// 	}
	// }

	const metaOgImage =
		typeof metaData?.ogImage !== "undefined"
			? `https://dreambroker.com${metaData?.ogImage}`
			: "https://dreambroker.com/DB_logo_RGB"

	return (
		<Head>
			<title>{metaData?.title}</title>
			<meta content={metaData?.title} name={"title"} />
			<meta content={metaData?.description} name={"description"} />
			<meta content={metaData?.keywords} name={"keywords"} />
			<meta content={metaData?.author} name={"author"} />
			<meta content={metaData?.ogTitle} property={"og:title"} />
			<meta content={metaData?.ogType} property={"og:type"} />
			<meta
				content={metaData?.ogDescription}
				property={"og:description"}
			/>
			<meta content={metaOgImage} property={"og:image"} />
			<meta content={"summary_large_image"} property={"twitter:card"} />
			<meta content={"@DreamBrokerLtd"} property={"twitter:title"} />
			<meta
				content={metaData?.ogDescription}
				property={"twitter:description"}
			/>
			<meta content={metaOgImage} property={"twitter:image"} />
			<meta content={URL} property={"og:url"} />
			<link href={"/favicon.ico"} rel={"icon"} />
			<meta content={router.locale} property={"locale"} />
			<meta content={"Dream Broker"} property={"og:site_name"} />
			<meta
				content={"width=device-width, initial-scale=1"}
				name={"viewport"}
			/>
		</Head>
	)
}

export default HeadMeta
