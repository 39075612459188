import styles from "./Overlay.module.scss"

type Props = {
	opacity?: string
	color?: string
}

const Overlay = (props: Props) => {
	return (
		<div
			className={styles.overlay}
			style={{
				opacity: props.opacity ? props.opacity : "0.5",
				backgroundColor: props.color ?? "black",
			}}
		/>
	)
}

export default Overlay
