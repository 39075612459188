import Asset from "@components/CustomTypography/Asset/Asset"
import NewResponsiveImage from "@components/CustomTypography/NewResponsiveImage/NewResponsiveImage"
import Overlay from "@components/CustomTypography/Overlay/Overlay"
import Paragraph from "@components/CustomTypography/Paragraph/Paragraph"
import Title from "@components/CustomTypography/Title/Title"
import ButtonSwitcher from "@components/Switchers/ButtonSwitcher/ButtonSwitcher"
import {HeroModuleType} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"

import ContainerWrapper from "../Wrappers/ContainerWrapper/ContainerWrapper"

import styles from "./FullWidthHeaderAdvanced.module.scss"
import ImageBackground from "./ImageBackground"
import VideoBackground from "./VideoBackground"

const FullWidthHeaderAdvanced = (props: {
	data: HeroModuleType
	className?: string
	textColor?: "black" | "white"
	withOverlay?: boolean
	popupType?: "content" | "asset" | "default"
	titleWithoutHtml?: boolean
	titleStyle?: string
	subtitleStyle?: string
	opacityNumber?: string
	overlayColor?: string
}) => {
	const type = props.data.desktopAsset?.type || "none"

	const typeClass = styles[type]

	const contentClass = `${styles.video__content} ${typeClass}`
	const buttonsClass = `${styles.video__content_buttons}`

	const background = type === "none" ? "#f5f5f5" : "transparent"

	return (
		<div style={{background}}>
			<div
				className={`${styles.backgroundVideo} ${typeClass} ${props.className}`}
			>
				<div className={styles.content__container}>
					<ContainerWrapper className={`${styles.contentBox} `}>
						<div
							className={contentClass}
							style={{color: props.textColor}}
						>
							<div className={styles.titleContainer}>
								<div className={styles.titleBox}>
									{props.data.beforeTitlesIcon && (
										<NewResponsiveImage
											containerClassName={`${styles.iconContainer}`}
											imageClassName={`${styles.icon} ${
												props.data.rightSideAsset
													?.filename
													? styles.disableIcon
													: ""
											}`}
											{...props.data.beforeTitlesIcon}
										/>
									)}
									<div>
										<Title
											className={`${styles.title} ${props.titleStyle}`}
											content={props.data.title}
											size={"XL"}
											withoutHtml={props.titleWithoutHtml}
										/>
										<Paragraph
											className={`${styles.subtitle} ${props.subtitleStyle}`}
											content={props.data.subtitle}
											size={"M"}
										/>
										{props.data.buttons?.[0] && (
											<div className={buttonsClass}>
												<ButtonSwitcher
													buttonsList={props.data}
													popup={{
														type: props.popupType
															? props.popupType
															: "asset",
														data: props.data,
													}}
													withoutTarget
												/>
											</div>
										)}
									</div>
								</div>
								{props.data.rightSideAsset && (
									<Asset
										data={{
											...props.data.rightSideAsset,
											containerClassName:
												styles.rightImageContainer,
											imageClassName: styles.rightImage,
										}}
										type={props.data.rightSideAsset.type}
									/>
								)}
							</div>
						</div>
					</ContainerWrapper>
				</div>
				{type === "video" && props.data.desktopAsset && (
					<VideoBackground
						desktop={props.data.desktopAsset}
						mobile={props.data.mobileAsset}
					/>
				)}

				{type === "image" && props.data.desktopAsset && (
					<ImageBackground
						desktop={props.data.desktopAsset}
						mobile={props.data.mobileAsset}
					/>
				)}
				{props.withOverlay && (
					<Overlay
						color={props.overlayColor}
						opacity={props?.opacityNumber ?? "0.4"}
					/>
				)}
			</div>
		</div>
	)
}

export default FullWidthHeaderAdvanced
